import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { getPosts } from './ApiRestWp'; // Asegúrate de que la ruta es correcta

import '../css/menu-documentos.css';

const BlogPosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const { data: postsData } = await getPosts('', 3); // Llama a getPosts con perPage=3
        setPosts(postsData);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setError('Error fetching posts');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return (
      <div className='contenedor-load'>
        <div className="three-body">
          <div className="three-body__dot"></div>
          <div className="three-body__dot"></div>
          <div className="three-body__dot"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='contenedor-documentos'>
      {posts.map(post => (
        <div className='item-documento' key={post.id}>
          <h5 className='titulo-documento'>
            {post.title.rendered}
          </h5>
          <div className='resumen-doc'>{parse(post.excerpt.rendered)}</div>
          <Link className='ver-mas' to={`/${post.slug}`}>Ver documento<i className="bi bi-arrow-right-short icono-ver-mas" /></Link>
        </div>
      ))}
    </div>
  );
};

export default BlogPosts;

