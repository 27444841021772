import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getBeneficios } from './ApiRestWp'; // Asegúrate de que la ruta es correcta

const BeneficiosIndex = () => {
  const [beneficios, setBeneficios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBeneficios = async () => {
      try {
        const { data: beneficiosData } = await getBeneficios(3); // Llama a getBeneficios con perPage=3
        if (Array.isArray(beneficiosData)) {
          setBeneficios(beneficiosData);
        } else {
          throw new Error('La respuesta de la API no es un array');
        }
      } catch (error) {
        console.error('Error fetching beneficios:', error);
        setError('Error fetching beneficios');
      } finally {
        setLoading(false);
      }
    };

    fetchBeneficios();
  }, []);

  if (loading) {
    return (
      <div className='contenedor-load'>
        <div className="three-body">
          <div className="three-body__dot"></div>
          <div className="three-body__dot"></div>
          <div className="three-body__dot"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='contenedor-beneficios'>
      {beneficios.map(beneficio => (
        <div className='item-beneficio' key={beneficio.id}>
          <h5 className='titulo-documento'>
            {beneficio.title.rendered}
          </h5>
          <Link className='ver-mas' to={`/beneficio/${beneficio.slug}`}>Ver Beneficio<i className="bi bi-arrow-right-short icono-ver-mas" /></Link>
        </div>
      ))}
      <div className='item-beneficio'>Beneficio</div>
      <div className='item-beneficio'>Beneficio</div>
    </div>
  );
};

export default BeneficiosIndex;

