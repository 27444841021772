import React from 'react';
import { Link } from 'react-router-dom';


import 'bootstrap-icons/font/bootstrap-icons.css';
import '../css/footer.css'

const faviconUrl = `${process.env.PUBLIC_URL}/favicon.ico`;

export default function Footer() {
  return (
    <div>
        <footer className="footer">
            <div className="contenedor-footer">
                <div className="logo-footer">
                    
                    <div className='contenedor-titulo-nav justify-content-center'>
                        <img  className='titulo-img-nav' src={faviconUrl} alt="Favicon" />
                        <p className='titulo-nav'>SITP</p>
                    </div>

                </div>
                <div className='contenedor-paginas'>
                    <Link to={'/'} className='paginas-footer'>Inicio</Link>
                    <Link to={'/historia'} className='paginas-footer'>Historia</Link>
                    <Link to={'/documentos'} className='paginas-footer'>Documentos</Link>
                    <Link to={'/beneficios'} className='paginas-footer'>Beneficios</Link>
                    <Link to={'/contacto'} className='paginas-footer'>Contactanos</Link>
                </div>
                <div className='contenedor-redes'>
                    <Link to={'https://www.facebook.com/'}><i className="bi bi-facebook iconos"></i></Link>
                    <Link to={'https://www.instagram.com/'}><i className="bi bi-instagram iconos"></i></Link>
                    <Link to={'https://x.com/'}><i className="bi bi-twitter-x iconos"></i></Link>
                    <Link to={'https://www.linkedin.com/'}><i className="bi bi-linkedin iconos"></i></Link>
                </div>
                <div className='contenedor-copy'>
                    <p className='texto-copy'><Link className='no-link' to={'/'}>Sitp.cl</Link> © 2024. Todos los derechos reservados.</p>
                </div>
            </div>
        </footer>
    </div>
  )
}
