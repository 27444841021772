import axios from 'axios';

const BASE_URL = 'http://sitp.cl/wp/wp-json/wp/v2';

export const getPosts = async (category = '', perPage = 1, offset = 0) => {
  try {
    let url = `${BASE_URL}/posts?per_page=${perPage}&offset=${offset}&_embed`;
    if (category) {
      url += `&categories=${category}`;
    }
    const response = await axios.get(url);

    // Retorna los datos y los headers
    return { data: response.data, headers: response.headers };
  } catch (error) {
    console.error('Error fetching posts:', error);
    return { data: [], headers: {} };
  }
};


export const getCategories = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/categories`);
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    return [];
  }
};

export const getPostBySlug = async (slug) => {
  try {
    const response = await axios.get(`${BASE_URL}/posts?slug=${slug}&_embed`);
    return response.data[0];  // El primer (y único) resultado
  } catch (error) {
    console.error('Error fetching post by slug:', error);
    return null;
  }
};

export const getPostExcerpts = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/posts`);
    // Mapea los datos para devolver solo los extractos de las entradas
    const excerpts = response.data.map(post => ({
      id: post.id,
      title: post.title.rendered,
      excerpt: post.excerpt.rendered, // Asegúrate de que el API devuelve un campo 'excerpt' adecuadamente
      slug: post.slug,
    }));
    return excerpts;
  } catch (error) {
    console.error('Error fetching post excerpts:', error);
    return [];
  }
};

export const getBeneficios = async (perPage = 1, offset = 0) => {
  try {
    let url = `${BASE_URL}/beneficio?per_page=${perPage}&offset=${offset}&_embed`;
    const response = await axios.get(url);

    // Retorna los datos y los headers
    return { data: response.data, headers: response.headers };
  } catch (error) {
    console.error('Error fetching posts:', error);
    return { data: [], headers: {} };
  }
};


export const getBeneficioBySlug = async (slug) => {
  try {
    let url = `${BASE_URL}/beneficio?slug=${slug}&_embed`;
    const response = await axios.get(url);

    // Retorna los datos y los headers
    return { data: response.data[0], headers: response.headers };
  } catch (error) {
    console.error('Error fetching post by slug:', error);
    return { data: null, headers: {} };
  }
};
