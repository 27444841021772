import React from 'react'
import Error404 from '../components/error'

export default function NotFound() {
  return (
    <div>
      <Error404 />
    </div>
  )
}

