import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getPosts } from './ApiRestWp'; // Asegúrate de que la ruta es correcta

const EntradasPosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const { data: postsData } = await getPosts('', 3); // Llama a getPosts con perPage=3
        setPosts(postsData);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setError('Error fetching posts');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return (
      <div className='contenedor-load'>
        <div className="three-body">
          <div className="three-body__dot"></div>
          <div className="three-body__dot"></div>
          <div className="three-body__dot"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='contenedor-entradas-post'>
      <div className='contenedor-titulo-entrada-posts'>
        <h3 className='titulo-entradas-posts'>Últimos documentos</h3>
      </div>
      {posts.map(post => (
        <div className='item-entrada-post' key={post.id}>
          <Link className='link-entrada-post' to={`/${post.slug}`}>{post.title.rendered}</Link>
        </div>
      ))}
    </div>
  );
};

export default EntradasPosts;

