import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const closeModalSuccess = () => {
    setShowModalSuccess(false);
  };

  const closeModalError = () => {
    setShowModalError(false);
  };

  useEffect(() => {
    if (showModalSuccess || showModalError) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showModalSuccess, showModalError]);

  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    mensaje: ''
  });

  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [error, setError] = useState({
    nombre: '',
    correo: '',
    mensaje: '',
    recaptcha: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setError({ ...error, [name]: '' });
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    setError({ ...error, recaptcha: '' });
  };

  const isEmailValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let formErrors = {};
    let hasErrors = false;

    if (!formData.nombre) {
      formErrors.nombre = 'Por favor ingrese su nombre.';
      hasErrors = true;
    }
    if (!formData.correo) {
      formErrors.correo = 'Por favor ingrese su correo electrónico.';
      hasErrors = true;
    } else if (!isEmailValid(formData.correo)) {
      formErrors.correo = 'Ingrese un correo electrónico válido.';
      hasErrors = true;
    }
    if (!formData.mensaje) {
      formErrors.mensaje = 'Por favor ingrese un mensaje.';
      hasErrors = true;
    }
    if (!recaptchaToken) {
      formErrors.recaptcha = 'Por favor complete el reCAPTCHA.';
      hasErrors = true;
    }

    if (hasErrors) {
      setError(formErrors);
      return;
    }

    try {
      const response = await fetch('/correo.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          ...formData,
          'g-recaptcha-response': recaptchaToken
        })
      });

      if (response.ok) {
        setShowModalSuccess(true);
        setFormData({
          nombre: '',
          correo: '',
          mensaje: ''
        });
        setError({
          nombre: '',
          correo: '',
          mensaje: '',
          recaptcha: ''
        });
      } else {
        setShowModalError(true);
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      setShowModalError(true);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} noValidate>
        <div className='contenedor-form'>
          <div className='contenedor-flex-1'>
            <div className='contenedor-inputs'>
              <label htmlFor="nombre">Nombre:</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={formData.nombre}
                onChange={handleInputChange}
              />
              {error.nombre && <p className="error-message">{error.nombre}</p>}
            </div>
            <div className='contenedor-inputs'>
              <label htmlFor="correo">Correo Electrónico:</label>
              <input
                type="email"
                id="correo"
                name="correo"
                value={formData.correo}
                onChange={handleInputChange}
              />
              {error.correo && <p className="error-message">{error.correo}</p>}
            </div>
          </div>
          <div className='contenedor-flex-2'>
            <div className='contenedor-inputs'>
              <label htmlFor="mensaje">Mensaje:</label>
              <textarea
                id="mensaje"
                name="mensaje"
                value={formData.mensaje}
                onChange={handleInputChange}
              ></textarea>
              {error.mensaje && <p className="error-message">{error.mensaje}</p>}
            </div>
            <div className='recaptcha'>
              <ReCAPTCHA
                className='item-recaptcha'
                sitekey="6LeJ1F0pAAAAAOE1bsmROb0JYJ4ZRu5tl_N_HnTQ"
                onChange={handleRecaptchaChange}
              />
              {error.recaptcha && <p className="error-message">{error.recaptcha}</p>}
            </div>
            <div className='contenedor-boton'>
              <button type="submit">Enviar mensaje</button>
            </div>
          </div>
        </div>
        {error.global && <p className="error-message">{error.global}</p>}
      </form>

      {showModalSuccess && (
        <div className="modal show d-block" tabIndex={'-1'} role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content d-flex flex-column justify-content-center p-2">
              <div className="d-flex flex-column align-items-end">
                <button type="button" className="btn-close" aria-label="Close" onClick={closeModalSuccess}></button>
              </div>
              <div className="modal-body text-align-center d-flex flex-column align-items-center justify-content-center gap-3">
                <h3>¡Su mensaje ha sido enviado con éxito!</h3>
                <p>Le responderemos a la brevedad.</p>
              </div>
              <div className="d-flex flex-column align-items-end justify-content-center">
                <button type="button" className="btn btn-secondary" onClick={closeModalSuccess}>Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModalError && (
        <div className="modal show d-block" tabIndex={'-1'} role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content d-flex flex-column justify-content-center p-2">
              <div className="d-flex flex-column align-items-end">
                <button type="button" className="btn-close" aria-label="Close" onClick={closeModalError}></button>
              </div>
              <div className="modal-body text-align-center d-flex flex-column align-items-center justify-content-center gap-3">
                <h3>Ha ocurrido un error :c</h3>
                <p>Por favor intentar en otro momento</p>
              </div>
              <div className="d-flex flex-column align-items-end justify-content-center">
                <button type="button" className="btn btn-secondary" onClick={closeModalError}>Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;








