import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { getPosts, getCategories, getPostExcerpts } from './ApiRestWp';

const CategoryDocs = () => {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 10;

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      setError('');
      try {
        const offset = (currentPage - 1) * postsPerPage;
        const { data: postsData, headers } = await getPosts(selectedCategory, postsPerPage, offset);
        const excerptsData = await getPostExcerpts();
  
        const postsWithExcerpts = postsData.map(post => {
          const excerpt = excerptsData.find(excerpt => excerpt.id === post.id)?.excerpt || '';
          return {
            ...post,
            excerpt: excerpt,
          };
        });
  
        setPosts(postsWithExcerpts);
  
        // Calcula el número total de páginas a partir de los headers
        const totalPosts = headers['x-wp-total']; // Header con el total de posts
        setTotalPages(Math.ceil(totalPosts / postsPerPage));
      } catch (error) {
        console.error('Error fetching posts:', error);
        setError('Error fetching posts');
        setPosts([]);
      } finally {
        setLoading(false);
      }
    };
  
    fetchPosts();
  }, [selectedCategory, currentPage]);
  

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await getCategories();
        setCategories(categoriesData);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setCategories([]);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(startPage + 2, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`item-pagination-number  ${currentPage === i ? 'activo' : ''}`}>
          <button className="pagination-btn-number" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div className='contenedor-documentos-cat'>
      <div className='contenedor-filtro-categoria'>
        <button
          key="all"
          className={`category-button ${selectedCategory === '' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('')}
        >
          Todas las categorías
        </button>
        {categories.map((category) => (
          <button
            key={category.id}
            className={`category-button ${selectedCategory === category.id ? 'active' : ''}`}
            onClick={() => handleCategoryChange(category.id)}
          >
            {category.name}
          </button>
        ))}
      </div>

        {loading ? (
          <div className='contenedor-load'>
            <div className="three-body">
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
            </div>
          </div>
        ) : (
          <div className='contenedor-post'>
            {posts.length === 0 && !error && <p>No hay posts disponibles</p>}
            {posts.map(post => (
              <div className='item-post' key={post.id}>
                <p className='titulo-post'>{post.title.rendered}</p>
                <div className='extracto-post'>{parse(post.excerpt)}</div>
                <Link className='link-item-post' to={`/${post.slug}`}>Ver más<i className="bi bi-arrow-right-short icono-link-post" /></Link>
              </div>
            ))}
          </div>
        )}
        {error && <div>{error}</div>}

      <nav aria-label="navigation page for docs">
        <ul className="contenedor-pagination">
          <li className={`item-pagintaion ${currentPage === 1 ? 'disabled' : ''}`}>
            <button 
              className="pagination-btn" 
              onClick={() => handlePageChange(currentPage - 1)} 
              aria-label="Previous"
              disabled={currentPage === 1}
            >
              <i className="icono-pagination bi bi-chevron-left"/>
            </button>
          </li>
          {renderPageNumbers()}
          <li className={`item-pagination ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button 
              className="pagination-btn" 
              onClick={() => handlePageChange(currentPage + 1)} 
              aria-label="Next"
              disabled={currentPage === totalPages}
            >
              <i className="icono-pagination bi bi-chevron-right"/>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default CategoryDocs;









