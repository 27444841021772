import React from 'react'

import '../css/directorio.css';
import carta from '../img/frank.jpg'

export default function Directorio() {
  return (
    <div className='contenedor-directorio'>
        <div className='contenedor-titulo-directorio'>
            <h1>Directorio</h1>
            <p>Conoce a la directiva de este sindicato</p>
        </div>
        <div className='contenedor-item-directorio'>
            <div className='item-directorio'>
                <img className='img-fluid' src={carta} alt="carta" />
                <p className='nombre-dir'>Nombre persona</p>
                <p className='cargo-dir'>cargo</p>
                <p className='contacto-dir'>correo@correo.com</p>
                <p className='contacto-dir'>+56912345678</p>
            </div>
            <div className='item-directorio'>
                <img className='img-fluid' src={carta} alt="carta" />
                <p className='nombre-dir'>Nombre persona</p>
                <p className='cargo-dir'>cargo</p>
                <p className='contacto-dir'>correo@correo.com</p>
                <p className='contacto-dir'>+56912345678</p>
            </div>

            <div className='item-directorio'>
                <img className='img-fluid' src={carta} alt="carta" />
                <p className='nombre-dir'>Nombre persona</p>
                <p className='cargo-dir'>cargo</p>
                <p className='contacto-dir'>correo@correo.com</p>
                <p className='contacto-dir'>+56912345678</p>
            </div>
            <div className='item-directorio'>
                <img className='img-fluid' src={carta} alt="carta" />
                <p className='nombre-dir'>Nombre persona</p>
                <p className='cargo-dir'>cargo</p>
                <p className='contacto-dir'>correo@correo.com</p>
                <p className='contacto-dir'>+56912345678</p>
            </div>
            <div className='item-directorio'>
                <img className='img-fluid' src={carta} alt="carta" />
                <p className='nombre-dir'>Nombre persona</p>
                <p className='cargo-dir'>cargo</p>
                <p className='contacto-dir'>correo@correo.com</p>
                <p className='contacto-dir'>+56912345678</p>
            </div>
            <div className='item-directorio'>
                <img className='img-fluid' src={carta} alt="carta" />
                <p className='nombre-dir'>Nombre persona</p>
                <p className='cargo-dir'>cargo</p>
                <p className='contacto-dir'>correo@correo.com</p>
                <p className='contacto-dir'>+56912345678</p>
            </div>

        </div>
    </div>
  )
}
