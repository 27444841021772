import React from 'react';

import '../css/pag-docs.css';
 import CategoryDocs from '../components/CategoryDocs';


export default function Documentos() {
  return (
    <div>
        <div className="contenedor">
            <div className='contenedor-titulo-doc'>
              <h1 className='titulo'>Documentos</h1>
            </div>

            <CategoryDocs />
        </div>
    </div>
  )
}
