import React from 'react';
import { Link } from 'react-router-dom';

import '../css/inicio.css';
import Documentos from '../components/entradas-wp.jsx';
import Beneficios from '../components/BeneficiosIndex.jsx'

import '../css/menu-documentos.css';

function Inicio() {
  return (
    <div>
      <div className="contenedor-banner-inicio">
        <div className="contenedor-texto-inicio">
          <h1 className="titulo-inicio">SINDICATO INTEREMPRESA DE TRABAJADORES Y PROFESIONALES</h1>
        </div>
      </div>

      <div className="contenedor-secciones-inicio">
        <div className='contenedor-titulo-secciones'>
          <h2 className='titulo-seccion'>Documentos</h2>
          <h5 className='subtitulo-seccion'>Actualizate con los ultimos documentos subidos</h5>
        </div>

        <Documentos />
        
        <div className='contenedor-ver-documentos'>
          <Link className='ver-documentos' to='/documentos'>Ver todos</Link>
        </div>
      </div>

      <div>
        <div className="contenedor-secciones-inicio">
          <div className='contenedor-titulo-secciones'>
            <h2 className='titulo-seccion'>Beneficios</h2>
            <h5 className='subtitulo-seccion'>Conoce los beneficios que te ofrece el sindicato</h5>
          </div>
          <Beneficios />

          <div className='contenedor-ver-documentos'>
            <Link className='ver-documentos' to='/beneficios'>Ver todos</Link>
          </div>

        </div>
      </div>

    </div>
  );
}

export default Inicio;
