import React from 'react';

import '../css/contacto.css'

import ContactForm from '../components/Form.jsx';

export default function Contacto() {
  return (

    <div className='contenedor-contacto'>
      <div className='contenedor-titulo'>
        <h1>¡Contactanos!</h1>
      </div>
      
      <ContactForm />
    </div>
    
    );
}
