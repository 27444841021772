import { Link } from 'react-router-dom';


const inicioUrl = '/'
const documentosUrl = '/documentos';
const contactoUrl = '/contacto';
const historialUrl = '/historia';
const beneficiosUrl = '/beneficios';

const faviconUrl = `${process.env.PUBLIC_URL}/favicon.ico`;


export default function Nav() {
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary sticky-top">
      <div className="container-fluid">
        <Link className="navbar-brand d-flex justify-content-start" to={inicioUrl}>
          <div className='contenedor-titulo-nav'>
            <img  className='titulo-img-nav' src={faviconUrl} alt="Favicon" />
            <p className='titulo-nav'>SITP</p>
          </div>
        </Link>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link ps-4" aria-current="page" to={inicioUrl}>Inicio</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link ps-4" to={historialUrl}>Historia</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link ps-4" to={beneficiosUrl}>Beneficios</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link ps-4" to={documentosUrl}>Documentos</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link ps-4" to={contactoUrl}>Contacto</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}


