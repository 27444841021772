import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

import { getPostBySlug } from './ApiRestWp'; // Asegúrate de que la ruta es correcta

import Entradas from './entrdas-posts';

import '../css/doc.css'; // Asegúrate de que la ruta es correcta

const Post = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postData = await getPostBySlug(slug);
        if (!postData) {
          navigate('/404'); // Redirige a la página 404 si no se encuentra el post
        } else {
          setPost(postData);
        }
      } catch (error) {
        console.error('Error fetching post by slug:', error);
        setError('Error fetching post');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug, navigate]);

  if (loading) {
    return (
      <div className='contenedor-load'>
        <div className="three-body">
          <div className="three-body__dot"></div>
          <div className="three-body__dot"></div>
          <div className="three-body__dot"></div>
        </div>
        <div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!post) {
    return null; // No renderiza nada si el post es null
  }

  return (
    <div className="contenedor-posts">
      <div className="contenedor-wp">
        <h1 className="post-title">{post.title.rendered}</h1>
        <div className="contenedor-parrafos">
          <div className="parrafos">
            <div className="post-content">{parse(post.content.rendered)}</div>
          </div>
        </div>
      </div>
      <div className="contenedor-entradas">
        <Entradas />
      </div>
    </div>
  );
};

export default Post;





