import React from 'react'

export default function error() {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center' style={{ margin: '100px 0' }}>
      <h1>ERROR 404</h1>
      <h4>No hemos encontrado lo que estas buscando :c</h4>
    </div>
  )
}

