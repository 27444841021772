import React from 'react';

import '../css/historia.css';

import Directorio from '../components/directorio'
import quienes from '../img/quienes-somos.jpg';

export default function Historia() {
  return (
    <div className='contenedor-historia'>
      <div className='contenedor-titulo-historia'>
        <h1>¿Quienes somos?</h1>
      </div>
      <div className='contenedor-texto-historia'>
        <p>El sindicato comienza en el año 2015 cuando ...</p>
      </div>

      <div className='contenedor-contexto'>
        <div className='img-contexto'>
          <img className='img-fluid' src={quienes} alt='imagen representante de la hisotria del sindicato'/>
        </div>
        <div className='contexto'>
          <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. </h3>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim id autem reiciendis, voluptas velit quo saepe veritatis quam nihil nesciunt commodi molestias, dignissimos expedita cum repellat consequatur! Asperiores, sint illum!</p>
        </div>
      </div>

      <Directorio />
    </div>
  )
}
