import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getBeneficios } from './ApiRestWp'; // Asegúrate de que la ruta es correcta

const EntradaBeneficios = () => {
  const [beneficios, setBeneficios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBeneficios = async () => {
      try {
        const { data: beneficioData } = await getBeneficios(3); // Obtén 3 beneficios
        setBeneficios(beneficioData);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setError('Error fetching posts');
      } finally {
        setLoading(false);
      }
    };

    fetchBeneficios();
  }, []);

  if (loading) {
    return (
      <div className='contenedor-load'>
        <div className="three-body">
          <div className="three-body__dot"></div>
          <div className="three-body__dot"></div>
          <div className="three-body__dot"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='contenedor-entradas-post'>
      <div className='contenedor-titulo-entrada-posts'>
        <h3 className='titulo-entradas-posts'>Últimos beneficios</h3>
      </div>
      {beneficios.map(beneficio => (
        <div className='item-entrada-post' key={beneficio.id}>
          <Link className='link-entrada-post' to={`/beneficio/${beneficio.slug}`}>{beneficio.title.rendered}</Link>
        </div>
      ))}
    </div>
  );
};

export default EntradaBeneficios;

