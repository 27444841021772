import React from 'react'

import BeneficiosPag from '../components/BeneficiosPag';

import '../css/beneficios.css'

export default function Beneficios() {
  return (
    <div className='contenedor-beneficios-page'>
      <h1 className='titulo-beneficio'>Beneficios</h1>
      <BeneficiosPag />
    </div>
  )
}
