import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import Inicio from '../pages/Inicio';
import Documentos from '../pages/Documentos';
import Post from './Posts';
import PostBeneficio from './PostBeneficio';
import Contacto from '../pages/Contacto';
import Historia from '../pages/Historia';
import Beneficios from '../pages/Beneficios';
import NotFound from '../pages/NotFound'

import Nav from './nav'
import Footer from './footer'

// Componente para gestionar el scroll al cambiar de ruta
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Nav />
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/documentos" element={<Documentos />} />
          <Route path="/:slug" element={<Post />} />
          <Route path="/beneficio/:slug" element={<PostBeneficio />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/historia" element={<Historia />} />
          <Route path="/beneficios" element={<Beneficios />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;


