import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { getBeneficios } from './ApiRestWp'; // Asegúrate de que esta función esté correctamente importada

const BeneficiosPag = () => {
  const [benefits, setBenefits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const benefitsPerPage = 10;

  useEffect(() => {
    const fetchBenefits = async () => {
      setLoading(true);
      setError('');
      try {
        const offset = (currentPage - 1) * benefitsPerPage;
        const { data: benefitsData, headers } = await getBeneficios(benefitsPerPage, offset);
  
        setBenefits(benefitsData);
  
        // Calcula el número total de páginas a partir de los headers
        const totalBenefits = headers['x-wp-total']; // Header con el total de beneficios
        setTotalPages(Math.ceil(totalBenefits / benefitsPerPage));
      } catch (error) {
        console.error('Error fetching benefits:', error);
        setError('Error fetching benefits');
        setBenefits([]);
      } finally {
        setLoading(false);
      }
    };
  
    fetchBenefits();
  }, [currentPage]);
  

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(startPage + 2, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`item-pagination-number ${currentPage === i ? 'activo' : ''}`}>
          <button className="pagination-btn-number" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <>
      <div className='contenedor-beneficios'>
        {loading ? (
          <div className='contenedor-load'>
            <div className="three-body">
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
              <div className="three-body__dot"></div>
            </div>
          </div>
        ) : (
          <div className='contenedor-items-beneficios'>
            {benefits.length === 0 && !error && <p>No hay beneficios disponibles</p>}
            {benefits.map(benefit => (
              <div className='item-beneficio-page' key={benefit.id}>
                <p className='titulo-beneficio-item'>{benefit.title.rendered}</p>
                <div className='extracto-beneficio-item'>{parse(benefit.excerpt.rendered)}</div>
                <Link className='link-item-beneficio' to={`/beneficio/${benefit.slug}`}>Ver más<i className="bi bi-arrow-right-short icono-link-post" /></Link>
              </div>
            ))}
          </div>
        )}
        {error && <div>{error}</div>}
      </div>
      <nav aria-label="navigation page for docs">
        <ul className="contenedor-pagination">
          <li className={`item-pagintaion ${currentPage === 1 ? 'disabled' : ''}`}>
            <button 
              className="pagination-btn" 
              onClick={() => handlePageChange(currentPage - 1)} 
              aria-label="Previous"
              disabled={currentPage === 1}
            >
              <i className="icono-pagination bi bi-chevron-left"/>
            </button>
          </li>
          {renderPageNumbers()}
          <li className={`item-pagination ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button 
              className="pagination-btn" 
              onClick={() => handlePageChange(currentPage + 1)} 
              aria-label="Next"
              disabled={currentPage === totalPages}
            >
              <i className="icono-pagination bi bi-chevron-right"/>
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default BeneficiosPag;

